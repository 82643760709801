import React, { useEffect } from 'react';
import PageHeader from '../component/layout/pageheader';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { invoice } from '../store/invoice.store';
import Loader from '../utils/Loader';
import { BiArrowBack } from 'react-icons/bi';


const Invoice = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const invoiceData = useSelector((state) => state?.invoices?.invoice);
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(invoice({"id":id}));
    }, []);

    return (
        <div className="invoice-page">
            <PageHeader title={'Invoice Details'}  show={true}/>
            
            <section className="invoice-section ">

                <div className="container">
                <button className='btn' onClick={() => navigate(-1)}><BiArrowBack size={25} /><span className='mx-2 mb-5'>Invoices</span> </button>

                    <div className="row mt-5">

                        <div className="col-lg-8">
                            {invoiceData ? (
                            <div className="invoice-details">
                                <h2 className="invoice-number">Invoice #{invoiceData?.reference_number}</h2>
                                <div className="invoice-info">
                                    <p><strong>Amount:</strong> Ksh {invoiceData?.amount}</p>
                                    <p><strong>Status:</strong> {invoiceData?.status}</p>
                                    <p><strong>Due Date:</strong> {invoiceData?.date_due}</p>
                                </div>
                                <div className="invoice-items">
                                    <h3>Invoice Items</h3>
                                    <ul>
                                        {invoiceData?.invoice_items.length > 0 && invoiceData?.invoice_items.map((item) => (
                                            <li key={item.id}>
                                                <p>{item.narration}</p>
                                                <p className="item-amount">Ksh {item.amount}</p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>):(<Loader/>)
                        }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Invoice;
