import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import api from "../api";
import {setLocalStorage, getFromLocalStorage} from "../utils/local-storage";
import Notify from "../utils/Notify";

const initialState = {
    loading: false,
    instructions:{}
}

//asynchronous function , action , callback
export const ReceivePayments = createAsyncThunk(
    "receive_payments", async (_, thunkAPI) => {
        const state = thunkAPI.getState()
        try {            
            return await api.login(state?.login_form)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
    }      
})


export const invokeStk = createAsyncThunk(
    "invoke_stk", async (data, thunkAPI) => {
        const state = thunkAPI.getState()
        try {            
            return await api.invokeStk(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
    }      
})

export const paymentInstructions = createAsyncThunk(
    "payment_instructions", async (data, thunkAPI) => {
        const state = thunkAPI.getState()
        try {            
            return await api.getPaymentInstruction(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
    }      
})

const paymentSlice = createSlice({
    name: "payments",
    initialState,
    extraReducers: (builder) =>{
        //Receive payments
        builder.addCase(paymentInstructions.pending, (state, action)=>{
            state.loading = true

        })

        builder.addCase(paymentInstructions.fulfilled, (state, action)=>{
            state.loading = false
            state.instructions = action.payload

            if (action?.payload?.success === false) {
                Notify({ status: 421, message: action?.payload?.errors })
            } else if (action?.payload?.success === true) {
                Notify({ status: 200, message: action?.payload?.message })
            }
            
        })

        builder.addCase(paymentInstructions.rejected, (state, action)=>{
            state.loading = false
            
        })


        //stk 
        builder.addCase(invokeStk.pending, (state, action)=>{
            state.loading = true
        })

        builder.addCase(invokeStk.fulfilled, (state, action)=>{
            state.loading = false            
        })

        builder.addCase(invokeStk.rejected, (state, action)=>{
            state.loading = false
        })
        
        
    }
})

export default paymentSlice.reducer
