import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { loginUser } from '../../store/users.store';
import Header from '../../component/layout/header';
import PageHeader from '../../component/layout/pageheader';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getFromLocalStorage } from '../../utils/local-storage';
import Loader from '../../utils/Loader';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

// import FooterSection from '../../component/layout/footer';

const title = "Login";

const LoginPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state) => state.users.loading);
    const token = getFromLocalStorage('token') //Getting the user token
    const [showPassword, setShowPassword] = useState(false);

    const handleFormSubmit = (values, { setSubmitting }) => {
        dispatch(loginUser(values)).then((result) => {
            if (result?.payload?.token) {
                // navigate("/dashboard")      
                window.location.pathname = "/members";
            }
        })
        setSubmitting(false)
    }


    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div>
            <Header />
            {/* <PageHeader title={'Log In'}   show={true}/> */}
            <div className="login-section padding-tb ">
                <div className=" container">
                    <div className="account-wrapper">
                        <h3 className="title">{title}</h3>
                        <Formik
                            initialValues={{
                                email: "",
                                password: ""
                            }}
                            onSubmit={handleFormSubmit}
                        >
                            <Form className="account-form">
                                <p className='text-start'>Email *</p>

                                <div className="form-group row bg-light rounded-pill p-1">

                                    <div className='col-11 bg-transparent border-0'>
                                        <Field
                                            type="email"
                                            name="email"
                                            id="item01"
                                            className="bg-transparent border-0 text-dark"
                                            placeholder="Email *"
                                            required
                                        />
                                    </div>
                                </div>

                            <p className='text-start'>Password *</p>

                                <div className="form-group row bg-light rounded-pill p-1">
                                    <div className='col-11 bg-transparent border-0'>
                                        <Field
                                            type={showPassword ? "text" : "password"}
                                            name="password"
                                            id="item02"
                                            className="bg-light text-dark border-0"
                                            placeholder="Password *"
                                            required
                                        />
                                    </div>


                                    <div className="col-1 bg-transparent p-2" onClick={toggleShowPassword}>
                                        {showPassword ? (
                                            <FontAwesomeIcon
                                                icon={faEyeSlash}
                                                style={{ color: "black", fontSize: "15px" }}
                                                className="eye-icon "
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={faEye}
                                                style={{ color: "black", fontSize: "15px" }}
                                            />
                                        )}

                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                                        <div className="checkgroup">
                                            <input type="checkbox" name="remember" id="remember" />
                                            <label htmlFor="remember">Remember Me</label>
                                        </div>
                                        <a href="#">Forget Password?</a>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='mx-auto text-center mt-2'>{loading ? <Loader /> : ""} </div>

                                    <button disabled={loading} className="d-block lab-btn" type="submit"><span>Submit Now</span></button>
                                </div>
                            </Form>
                        </Formik>
                        <div className="account-bottom">
                            <span className="d-block cate pt-10">Don’t Have any Account? <Link to="/signup"> Sign Up</Link></span>
                        </div>
                    </div>
                </div>
            </div>
            {/* <FooterSection /> */}
            <ToastContainer />

        </div>
    );
};

export default LoginPage;
