import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";
import { setLocalStorage, getFromLocalStorage } from "../utils/local-storage";
import Notify from "../utils/Notify";

const initialState = {
    loading: false,
    events:{},
    event:{}
}

//asynchronous function , action , callback
export const event_s = createAsyncThunk(
    "events", async (data, thunkAPI) => {
        const state = thunkAPI.getState()
        try {
            return await api.events()
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    })


export const even_t = createAsyncThunk(
    "event", async (id, thunkAPI) => {
        const state = thunkAPI.getState()
        try {
            return await api.event(id)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    })


export const checkout = createAsyncThunk(
    "checkout", async (data, thunkAPI) => {
        const state = thunkAPI.getState()
        try {
            return await api.eventCheckout(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    })

const eventsSlice = createSlice({
    name: "Events",
    initialState,
    extraReducers: (builder) => {
        //events reducers
        builder.addCase(event_s.fulfilled, (state, action) => {
            state.loading = false
            state.events = action.payload

            if(action?.payload?.token){
                setLocalStorage('token', action?.payload?.token,5684000000)
                console.log("Login Successfull")
                setTimeout(function(){
                    Notify({ status: 200, message: "Login Succesfull" })
                },1000)
                
            }else if(action?.payload?.error){
                console.log("Login unsuccessfull")
            }
            
        })

        //if a user is not successfully logged in
        builder.addCase(event_s.rejected, (state, action) => {
            state.loading = false
            state.status = "error"
            state.errors = action?.payload?.errors
            console.log("Login unsuccessfull")
            Notify({ status: 421, message: "Invalid credentials" })
        })

        builder.addCase(event_s.pending, (state, action) => {
            state.loading = true;
        })



        //Signup reducers
        builder.addCase(even_t.pending, (state, action) => {
            state.loading = true;

        })

        builder.addCase(even_t.fulfilled, (state, action) => {
            state.loading = false
            state.event = action.payload
            // setTimeout(function(){
            //     Notify({ status: 200, message: "Registration Successfull, Proceed to Login" })
            // },1000)
            

            if (action?.payload?.success === false) {
                Notify({ status: 421, message: action?.payload?.errors })
            } else if (action?.payload?.success === true) {
                Notify({ status: 200, message: action?.payload?.message })
            }

        })

        builder.addCase(even_t.rejected, (state, action) => {
            state.loading = false
            Notify({ status: 421, message: action?.payload?.error })

        })


        //forgot paswword reducers
        builder.addCase(checkout.pending, (state, action) => {
            state.loading = true

        })

        builder.addCase(checkout.fulfilled, (state, action) => {
            state.loading = false
            state.authenticated = true

            // setTimeout(function(){
            //     Notify({ status: 200, message: "Check your email address for the OTP" })
            // },1000)
            
        })

        builder.addCase(checkout.rejected, (state, action) => {
            Notify({ status: 421, message: action?.payload?.error })
        })

    }
})

export default eventsSlice.reducer
