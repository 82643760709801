import React,{useState} from 'react';
import { useDispatch , useSelector} from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { add_user_preference } from "../../store/dating.store";
import BannerSection from "../../component/section/banner";
import Loader from "../../utils/Loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Select from 'react-select';
import localityData from '../../data/location_data.json';

const Settings = () => {
    const dispatch = useDispatch();
    const [isChecked, setIsChecked] = useState(false);
    const [minAge,setMinAge] = useState(18)
    const user_pref = useSelector((state) => state?.users?.accountDetails?.preferences)


    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        console.log("Has been checked",isChecked )
        console.log("Min value",minAge)
        if(isChecked){
            setMinAge(60)
        }
    };

    
    const cityOptions = localityData.map(locality => ({
        value: locality.fields.code,
        label: locality.fields.name
    }));

    const CitySearch = ({ field, form }) => {
        const [selectedOption, setSelectedOption] = useState(null);

        const customStyles = {
            control: (provided) => ({
                ...provided,
                backgroundColor: '',
                color: 'white',
                border:"1px solid grey",
                boxShadow: 'none' // optional: remove box shadow,

            }),
            menu: (provided) => ({
                ...provided,
                backgroundColor: '',
                color: 'dark'
            }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? 'blue' : 'black',
                color: state.isSelected ? 'white' : 'white',
                '&:hover': {
                    backgroundColor: 'gray'
                }
            }),
            singleValue: (provided) => ({
                ...provided,
                color: 'dark'
            }),
            placeholder: (provided) => ({
                ...provided,
                color: 'dark'
            })
        };
    
        const handleChange = (option) => {
            setSelectedOption(option);
            form.setFieldValue(field.name, option ? option.value : '');
        };
    
        return (
            <div className="city text-dark">
                <label className="left">City</label>
                <Select
                    className="right text-dark "
                    id=""
                    name="city"
                    placeholder="Your location.."
                    value={selectedOption}
                    onChange={handleChange}
                    options={cityOptions}
                    styles={customStyles} //
                />
            </div>
        );
    };

    const handleFormSubmit = (values, { setSubmitting }) => {
        dispatch(add_user_preference(values));
        setSubmitting(false);
    };

    return (
        <div className="">
            <h4>My Preferences</h4>
            <Formik
                initialValues={{
                    gender: '',
                    lookingFor: '',
                    ageStart: 18,
                    ageEnd: 100,
                    minAgeStart: 18,
                    minAgeEnd: 100,
                    city: '',
                    onlineDating: ''
                }}
                onSubmit={handleFormSubmit}
            >
                {({ values, setFieldValue }) => (
                    <Form className="banner-form">
                        <div className="gender">
                            <label className="left">I am a </label>
                            <div className="custom-select right">
                                <Field as="select" name="gender" id="gender" className="">
                                    {/* <option value="">{!user_pref?.gender ? user_pref?.gender : "select gender"}</option> */}
                                    {/* <option value="">{user_pref && user_pref.gender !== "" ? user_pref.gender : "select gender"}</option> */}
                                    <option value="MALE">Select Gender</option>
                                    <option value="MALE">Male</option>
                                    <option value="FEMALE">Female</option>
                                </Field>
                            </div>
                        </div>
                        <div className="person">
                            <label className="left">Looking for a</label>
                            <div className="custom-select right">
                                <Field as="select" name="lookingFor" id="lookingFor" className="">
                                    <option value="">Select Gender</option>
                                    <option value="MALE">Male</option>
                                    <option value="FEMALE">Female</option>
                                </Field>
                            </div>
                        </div>

                        {/* <div className="city">
                            <label className="left">City</label>
                            <Field className="right  form-control" type="text" name="city" id="city" placeholder="Your City Name.." 
                             style={{ color: 'white' }}
                            />
                        </div> */}

                        <div className="city">
                            {/* <label className="left">City</label> */}
                            <Field className="right form-control" type="text" 
                            component={CitySearch}   name="city" id="city" placeholder="Your City Name.." />
                        </div>
                        

                        <div className="age">
                            <label className="left">Age Range</label>
                            <div className="right d-flex flex-column">
                                <Slider
                                    range
                                    min={minAge}
                                    max={100}
                                    value={[values.ageStart, values.ageEnd]}
                                    onChange={([ageStart, ageEnd]) => {
                                        setFieldValue('ageStart', ageStart);
                                        setFieldValue('ageEnd', ageEnd);
                                    }}
                                    trackStyle={[{ backgroundColor: '#ff0844' }]}
                                    handleStyle={[
                                        { borderColor: '#ff0844', backgroundColor: '#ff0844' },
                                        { borderColor: '#ff0844', backgroundColor: '#ff0844' }
                                    ]}
                                    railStyle={{ backgroundColor: '#ddd' }}
                                />
                                <div className="d-flex justify-content-between mt-2">
                                    <span>{values.ageStart}</span>
                                    <span>{values.ageEnd}</span>
                                </div>
                            </div>
                        </div>

                        <label className="form-check-label">
                                <Field type="checkbox" name="onlineDating" value="over60" className="form-check-input"
                                checked={isChecked} 
                                onChange={handleCheckboxChange} 
                                />
                                <span className='mx-2'>Over 60 Years</span>
                        </label>
                
                        <div className="online-dating mt-3 d-flex">
                            <label className="left">Dating Preference</label>
                            <div className="mx-5 right d-flex justify-content-start ">
                                <label className="form-check-label">
                                    <Field type="radio" name="onlineDating" value="online" className="form-check-input" />
                                    <span className='mx-2'>Online</span>
                                </label>
                                <label className="form-check-label">
                                    <Field type="radio" name="onlineDating" value="offline" className="form-check-input" />
                                    <span className='mx-2'>Offline</span>
                                </label>
                             
                            </div>
                        </div>
                        <button type="submit" className="btn btn-success mt-3" disabled={false}>
                            {false ? "Updating Preferences..." : "Update Preferences"}
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default Settings;
