import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";
import { setLocalStorage, getFromLocalStorage } from "../utils/local-storage";
import Notify from "../utils/Notify";

const initialState = {
    accountDetails:{},
    loading: false,
    userDetails:{},
    users:{},
    user:{}
    // active_route:"account"
}


//asynchronous function , action , callback
export const loginUser = createAsyncThunk(
    "user/login", async (data, thunkAPI) => {
        const state = thunkAPI.getState()
        try {
            return await api.login(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    })


export const signupUser = createAsyncThunk(
    "user/signup", async (data, thunkAPI) => {
        const state = thunkAPI.getState()
        try {
            return await api.registerUser(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    })


export const upload = createAsyncThunk(
    "user/upload", async (data, thunkAPI) => {
        const state = thunkAPI.getState()
        try {
            return await api.upload(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    })


export const forgotPassword = createAsyncThunk(
    "user/forgot-password", async (data, thunkAPI) => {
        const state = thunkAPI.getState()
        try {
            return await api.forgotPassword(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    })


export const resetPassword = createAsyncThunk(
    "user/reset-password", async (data, thunkAPI) => {
        const state = thunkAPI.getState()
        try {
            
            return await api.resetPassword(data)
        } catch (e) {
            // Notify({ status: 421, message: e })
            return thunkAPI.rejectWithValue(e.response.data)
        }
    })

export const accountDetails = createAsyncThunk(
    "user/account-details", async (_, thunkAPI) => {
        const state = thunkAPI.getState()
        try {
            return await api.getAccountDetails()
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    })

export const users = createAsyncThunk(
 "user/members"  ,async (_,thunkAPI)=>{
    const state = thunkAPI.getState()
    try {
        return await api.users()        
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data)        
    }
 } 
)

export const user = createAsyncThunk(
    "user/member"  ,async (id,thunkAPI)=>{
       const state = thunkAPI.getState()
       try {
           return await api.user(id)        
       }catch(e){
           return thunkAPI.rejectWithValue(e.response.data)        
       }
    } 
   )






export const logout = () => ({
    type: 'logout',
});

const usersSlice = createSlice({
    name: "users",
    initialState,
    extraReducers: (builder) => {
        builder.addCase('active_route', (state, action) => {
            state.active_route = action?.payload
        })
        //Login reducers
        builder.addCase(loginUser.fulfilled, (state, action) => {
            state.loading = false
            state.authenticated = true

            console.log("The action is ", action)

            if(action?.payload?.token){
                setLocalStorage('token', action?.payload?.token,5684000000)
                console.log("Login Successfull",action?.payload?.token)
                setTimeout(function(){
                    Notify({ status: 200, message: "Login Succesfull" })
                },1000)
                
            }else if(action?.payload?.error){
                console.log("Login unsuccessfull")

            }
            
        })

        //if a user is not successfully logged in
        builder.addCase(loginUser.rejected, (state, action) => {
            state.loading = false
            state.status = "error"
            state.errors = action?.payload?.errors
            console.log("Login unsuccessfull", action)
            Notify({ status: 421, message: action.payload?.error })
        })

        builder.addCase(loginUser.pending, (state, action) => {
            state.loading = true;
        })



        //Signup reducers
        builder.addCase(signupUser.pending, (state, action) => {
            state.loading = true;

        })

        builder.addCase(signupUser.fulfilled, (state, action) => {
            state.loading = false
            setTimeout(function(){
                Notify({ status: 200, message: "Registration Successfull, Proceed to Login" })
            },1000)
            

            if (action?.payload?.success === false) {
                Notify({ status: 421, message: action?.payload?.errors })
            } else if (action?.payload?.success === true) {
                Notify({ status: 200, message: action?.payload?.message })
            }

        })

        builder.addCase(signupUser.rejected, (state, action) => {
            state.loading = false
            Notify({ status: 421, message: action?.payload?.error })

        })



        //Signup reducers
        builder.addCase(upload.pending, (state, action) => {
            state.loading = true;

        })

        builder.addCase(upload.fulfilled, (state, action) => {
            state.loading = false
            state.upload = action.payload

        })

        builder.addCase(upload.rejected, (state, action) => {
            state.loading = false
            Notify({ status: 421, message: action?.payload?.error })

        })


        //forgot paswword reducers
        builder.addCase(forgotPassword.pending, (state, action) => {
            state.loading = true

        })

        builder.addCase(forgotPassword.fulfilled, (state, action) => {
            state.loading = false
            state.authenticated = true

            // setTimeout(function(){
            //     Notify({ status: 200, message: "Check your email address for the OTP" })
            // },1000)
            
        })

        builder.addCase(forgotPassword.rejected, (state, action) => {
            Notify({ status: 421, message: action?.payload?.error })
        })

        //reset password reducers
        builder.addCase(resetPassword.pending, (state, action) => {
            state.loading = true

        })

        builder.addCase(resetPassword.fulfilled, (state, action) => {
            state.loading = false

            setTimeout(function(){
                Notify({ status: 200, message: "Password reset succesfully, please proceed to login" })
            },1000)
        
        })

        builder.addCase(resetPassword.rejected, (state, action) => {
            state.loading = false
            Notify({ status: 421, message: action?.payload?.error })


        })

        //account details reducers
        builder.addCase(accountDetails.pending, (state, action) => {
            state.loading = true

        })

        builder.addCase(accountDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.accountDetails = action.payload
            if (action?.payload?.success === false) {
                Notify({ status: 421, message: action?.payload?.errors })
            } else if (action?.payload?.success === true) {
                Notify({ status: 200, message: action?.payload?.message })
            }

        })

        builder.addCase(accountDetails.rejected, (state, action) => {
            state.loading = false

        })


        //members reducers
        builder.addCase(users.pending, (state, action) => {
            state.loading = true

        })

        builder.addCase(users.fulfilled, (state, action) => {
            state.loading = false;
            state.users = action.payload
            if (action?.payload?.success === false) {
                Notify({ status: 421, message: action?.payload?.errors })
            } else if (action?.payload?.success === true) {
                Notify({ status: 200, message: action?.payload?.message })
            }

        })

        builder.addCase(users.rejected, (state, action) => {
            state.loading = false
        })

        //member reducers
        builder.addCase(user.pending, (state, action) => {
            state.loading = true

        })

        builder.addCase(user.fulfilled, (state, action) => {
            state.loading = false;
            state.userDetails = action.payload
            if (action?.payload?.success === false) {
                Notify({ status: 421, message: action?.payload?.errors })
            } else if (action?.payload?.success === true) {
                Notify({ status: 200, message: action?.payload?.message })
            }

        })

        builder.addCase(user.rejected, (state, action) => {
            state.loading = false
        })

    }
})

export default usersSlice.reducer
