import React from 'react';

const testimonials = [
    {
        name: "Trevor Ogina",
        testimonial: "This platform has completely changed my dating experience. I've met some amazing people and had a lot of fun!",
        imageUrl: "/assets/images/member/02.jpg",
    },
    {
        name: "Diana Jones",
        testimonial: "Creating a profile was super easy and I was matched with people who share my interests. Highly recommended!",
        imageUrl: "/assets/images/member/03.jpg",
    },
    {
        name: "Sam Wilson",
        testimonial: "The dates I've gone on through this site have been fantastic. The matching algorithm really works!",
        imageUrl: "/assets/images/member/06.jpg",
    }
];

const TestimonialSection = () => {
    return (
        <section className="testimonial-section padding-tb">
            <div className="container">
                <div className="section-header">
                    <h4 className="theme-color">Testimonials</h4>
                    <h2>What Our Users Say</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row justify-content-center g-5">
                        {testimonials.map((testimonial, i) => (
                            <div className="col-lg-4 col-sm-6 col-12 px-4" key={i}>
                                <div className="lab-item testimonial-item text-center">
                                    <div className="lab-inner">
                                        <div className="lab-thumb">
                                            <img 
                                                src={testimonial.imageUrl} 
                                                alt={testimonial.name} 
                                                className="rounded-circle"
                                                style={{ width: "150px", height: "150px", objectFit: "cover" }}
                                            />
                                        </div>
                                        <div className="lab-content">
                                            <h4>{testimonial.name}</h4>
                                            <p>"{testimonial.testimonial}"</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TestimonialSection;
