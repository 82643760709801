import React, { useState, useEffect } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import "../../assets/css/header.css";
import { useSelector } from "react-redux";
import { getFromLocalStorage } from "../../utils/local-storage";

const Header = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const token = getFromLocalStorage("token");
  const user_name = useSelector(
    (state) => state?.users?.accountDetails?.full_name
  );
  const navigate = useNavigate();

  const menuTrigger = () => {
    document.querySelector(".menu").classList.toggle("active");
    document.querySelector(".header-bar").classList.toggle("active");
  };

  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);

  const menuTriggerTwo = () => {
    document.querySelector(".header-top").classList.toggle("open");
    // document.querySelector('.header-bar').classList.toggle('active')
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      var value = window.scrollY;
      if (value > 200) {
        document
          .querySelector(".header-section")
          .classList.add("header-fixed", "fadeInUp");
      } else {
        document
          .querySelector(".header-section")
          .classList.remove("header-fixed", "fadeInUp");
      }
    });
  }, []);

  const [hoveredLink, setHoveredLink] = useState(null);

  const handleMouseEnter = (link) => {
    setHoveredLink(link);
  };

  const handleMouseLeave = () => {
    setHoveredLink(null);
  };

  return (
    <header className="header-section sticky-header" style={{ zIndex: "1000" }}>
      <div className="header-top">
        <div className="container"></div>
      </div>
      <div className="header-bottom">
        <div className="container">
          <div className="header-wrapper">
            <div className="logo" style={{ width: "6cm" }}>
              <Link to="/">
                <div className="d-flex">
                  <h3>Match</h3>
                  <img
                    src="assets/images/logo/logo.png"
                    alt="logo"
                    style={{ width: "1.5cm" }}
                  />
                  <h3>Kenya</h3>
                </div>
              </Link>
            </div>
            <div className="menu-area">
              <ul className="menu">
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink
                    to="/online"
                    onMouseEnter={() => handleMouseEnter("online")}
                    onMouseLeave={handleMouseLeave}
                  >
                    Online
                  </NavLink>
                  {hoveredLink === "online" && (
                    <div className="preview">
                      <p>This is online dating</p>
                    </div>
                  )}
                </li>
                <li>
                  <NavLink to="/signup">Offline</NavLink>
                </li>
                <li>
                  <NavLink
                    to="/social"
                    onMouseEnter={() => handleMouseEnter("social")}
                    onMouseLeave={handleMouseLeave}
                  >
                    Social
                  </NavLink>
                  {hoveredLink === "social" && (
                    <div className="preview">
                      <p>This is social dating</p>
                    </div>
                  )}
                </li>
                <li>
                  <NavLink to="/signup">Over 60</NavLink>
                </li>
                {/* <li>
                                    <NavLink to="/events">Events</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/members">Members</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/packages">Packages</NavLink>
                                </li> */}
                {!token ? (
                  <>
                    {/* <li>
                                            <NavLink to="/contact">Contact</NavLink>
                                        </li> */}
                    <li className="mobi-auth">
                      <NavLink to="/login">Login</NavLink>
                    </li>
                    <li className="mobi-auth">
                      <NavLink to="/signup">Sign Up</NavLink>
                    </li>
                  </>
                ) : (
                  <li
                    className="mobi-auth"
                    // onClick={()=>handleShow()}
                  >
                    <NavLink to="#">
                      <i className="icofont-user"></i>
                      {user_name}
                    </NavLink>
                  </li>
                )}
              </ul>
              {!token ? (
                <>
                  <NavLink to="/login" className="login">
                    <i className="icofont-user"></i> <span>LOG IN</span>{" "}
                  </NavLink>
                  <NavLink to="/signup" className="signup">
                    <i className="icofont-users"></i> <span>SIGN UP</span>{" "}
                  </NavLink>
                </>
              ) : (
                <>
                  <NavLink
                    to="#"
                    className="login"
                    onClick={() => navigate("/members")}
                  >
                    <i className="icofont-user"></i> <span>My Account</span>{" "}
                  </NavLink>
                </>
              )}

              <div className="header-bar d-lg-none" onClick={menuTrigger}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="ellepsis-bar d-lg-none" onClick={menuTriggerTwo}>
                <i className="icofont-info-square"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
