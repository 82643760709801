import React, { useState } from 'react';
import { useSprings, animated } from '@react-spring/web';
import { useDrag } from 'react-use-gesture';
import Carousel from 'nuka-carousel';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HeartFill, X, XCircleFill } from 'react-bootstrap-icons';
import { update_component } from '../store/application.store';
import { AiOutlineHeart, AiOutlineEye, AiOutlineDislike } from 'react-icons/ai';
import { like_user } from '../store/dating.store';


const images = [
  "https://images.unsplash.com/photo-1618042164219-62c820f10723?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8dW5zcGFsc2h8ZW58MHx8MHx8fDA%3D",
  "https://images.unsplash.com/photo-1621272036047-bb0f76bbc1ad?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dW5zcGFsc2h8ZW58MHx8MHx8fDA%3D",
  "https://images.unsplash.com/photo-1639020715359-f03b05835829?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dW5zcGFsc2h8ZW58MHx8MHx8fDA%3D",
  // "https://images.unsplash.com/photo-1634455257140-b5ab16f0770e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHVuc3BhbHNofGVufDB8fDB8fHww"
]


const to = (i) => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: -10 + Math.random() * 80,
  delay: i * 100,
});





const from = (i) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 });

function Deck() {
  const data = useSelector((state) => state?.users?.users?.results);
  const navigate = useNavigate();
  const [showLoveIcon, setShowLoveIcon] = useState(false);
  const [showHateIcon, setShowHateIcon] = useState(false);
  const [noMore, setNoMore] = useState(false)
  const dispatch = useDispatch();


  const [gone] = useState(() => new Set());
  const [props, api] = useSprings(data?.length, (i) => ({
    ...to(i),
    from: from(i),
  }));

  const bind = useDrag(({ args: [index], down, movement: [mx], direction: [xDir], velocity }) => {
    const trigger = velocity > 0.2;
    const dir = xDir < 0 ? -1 : 1;


    if (!down && trigger) {
      gone.add(index);
      // toast.info(`You   ${dir === -1 ? 'disliked' : 'Liked'} ${data[index]?.full_name}`,{ autoClose: 500 });
      if (dir === 1) {
        setShowLoveIcon(true);
      //  toast.info(`You   ${dir === -1 ? 'disliked' : 'Liked'} ${data[index]?.id}`,{ autoClose: 500 });
        dispatch(like_user(data[index]?.id))
      } else if (dir === -1) {
        setShowHateIcon(true);
      }

      setTimeout(() => {
        setShowHateIcon(false);
        setShowLoveIcon(false);
      }, 500); // Adjust 
    }

    api.start((i) => {
      if (index !== i) return;

      const isGone = gone.has(index);
      const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0;
      const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0);
      const scale = down ? 1.1 : 1;

      return {
        x,
        rot,
        scale,
        delay: undefined,
        config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
      };
    });

    if (!down && gone.size === data?.length) {
      setTimeout(() => {
        gone.clear();
        // api.start((i) => to(i));
        setNoMore(true)
        //You can fetch more users here
      }, 600);
    }
  });

  const handleViewClicked = (value) => {
    dispatch(update_component("member"))
    console.log("The value is", value)
    // onClick={()=>navigate(`/profile/${data[i]?.id}`)}

  }

  return (
    <div id="swipe-root">
      <div className="container">
        <ToastContainer />

        <div className=''>
          {showLoveIcon && (
            <div className="love-icon">
              <HeartFill className="heart"/>
            </div>
          )}

          {noMore && (
            <div className='bg-transparent' style={{
              position:"absolute",top:"50%"
            }}>
              <h4><em>There are no more users...</em></h4>
              <hr/>
              </div>
          )}


          {showHateIcon && (
            <div className="love-icon">
              <X className="heart" />
            </div>
          )}

          {props.map(({ x, y }, i) => (
            <animated.div key={i} className="deck" style={{ x, y }}>
              <animated.div {...bind(i)}>
                <div className=''>
                  {data[i]?.images?.length >= 1 ? (
                    <Carousel>
                      {data[i]?.images.map((image, index) => (
                        // <img className='member-image' src={images[0]} alt="Place holder image" style={{ maxHeight: '500px', width: "100%" }} />
                        <img
                        key={index}
                        className='member-image' src={image.image.file} 
                        alt={`Image ${index}`}
                        style={{ maxHeight: '500px', width: "100%" }} />
                      ))}
                    </Carousel>
                  ) : (
                    <Carousel>
                      <img class="member-image" src={images[0]} alt="Place holder image" style={{ maxHeight: '500px', width: "100%" }} />
                    </Carousel>
                  )}
                  <div className='member-details row d-flex justify-content-around p-2'>
                    <div className='col-12 px-1'>
                      <div className='col-12 user-info px-3'>
                        <h4 className='text-light' >{data[i]?.first_name}  <span className='text-danger mx-3'>{data[i]?.age}</span></h4>
                        {/* <p><small><strong style={{color:"rgb(26, 35, 126)"}}>Status</strong>  : {data[i]?.account_status}</small></p> */}
                        <p><small><strong style={{color:"rgb(26, 35, 126)"}} className='text-light'><span className='text-danger'>Bio</span></strong><span className='mx-2'> {data[i]?.bio}</span></small></p>
                      </div>                   
                    </div>


                    <div className="row ">
                      <div className='col-4'>
                        <div className='ml-5 btn-circle'>
                          <AiOutlineDislike className='' size={25} color='pink'/>
                        </div>
                      </div>
                      <div className='col-4'>
                        <div className='btn-circle'>
                          <AiOutlineEye size={25} color='gray' />
                        </div>
                      </div>
                      <div className='col-4'>
                        <div className='btn-circle' onClick={() => dispatch(like_user(data[i]?.id))}>
                          <HeartFill color='red' size={26} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </animated.div>
            </animated.div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Deck;
