import React, { useState, useEffect } from 'react';
import Header from '../component/layout/header';
import BannerSection from '../component/section/banner';
import MemberSection from '../component/section/member';
import AboutSection from '../component/section/about';
import WorkSection from '../component/section/work';
import TestimonialSection from '../component/section/Testimonials';
import { useDispatch } from 'react-redux';
import { event_s } from '../store/events.store';
import FooterSection from '../component/layout/footer';

const quotes = [
    "Love is composed of a single soul inhabiting two bodies. (Aristotle)",
    "Love is that condition in which the happiness of another person is essential to your own. (Robert A. Heinlein)",
    "To love and be loved is to feel the sun from both sides. - David Viscott",
    "Love is an irresistible desire to be irresistibly desired. - Robert Frost",
    "Love is the only force capable of transforming an enemy into a friend. (Martin Luther King Jr.)",
    "The best thing to hold onto in life is each other. (Audrey Hepburn)",
    "Love is when the other person's happiness is more important than your own. (H. Jackson Brown Jr.)",
    "We are shaped and fashioned by what we love. (Johann Wolfgang von Goethe)",
    "Love cures people - both the ones who give it and the ones who receive it.  (Karl A. Menninger)",
    "Love is the flower you've got to let grow.  (John Lennon)",
    "Where there is love there is life.  (Mahatma Gandhi)",
    "To love and be loved is to feel the sun from both sides.  (David Viscott)",
    "Being deeply loved by someone gives you strength, while loving someone deeply gives you courage.  (Lao Tzu)",
    "The best and most beautiful things in the world cannot be seen or even touched - they must be felt with the heart.  (Helen Keller)",
    "Love is not about possession. Love is about appreciation.  (Osho)",
    "Love recognizes no barriers. It jumps hurdles, leaps fences, penetrates walls to arrive at its destination full of hope.  (Maya Angelou)",
    "Love is a friendship set to music.  (Joseph Campbell)",
    "To love and be loved is to feel the sun from both sides.  (David Viscott)",
    "The best thing to hold onto in life is each other.  (Audrey Hepburn)",
    "Where there is love there is life.  (Mahatma Gandhi)",
    "Love is the flower you've got to let grow.  (John Lennon)",
    "Love is composed of a single soul inhabiting two bodies.  (Aristotle)",
    "Love is that condition in which the happiness of another person is essential to your own.  (Robert A. Heinlein)",
    "To love and be loved is to feel the sun from both sides.  (David Viscott)",
    "Love is an irresistible desire to be irresistibly desired.  (Robert Frost)",
    "Love is the only force capable of transforming an enemy into a friend.  (Martin Luther King Jr.)",
    "The best thing to hold onto in life is each other.  (Audrey Hepburn)",
    "Love is when the other person's happiness is more important than your own.  (H. Jackson Brown Jr.)",
    "We are shaped and fashioned by what we love.  (Johann Wolfgang von Goethe)",
    "Love cures people - both the ones who give it and the ones who receive it.  (Karl A. Menninger)"
];

const HomePages = () => {
    const dispatch = useDispatch();
    const [quoteIndex, setQuoteIndex] = useState(0);
    

    useEffect(() => {
        const intervalId = setInterval(() => {
            setQuoteIndex(prevIndex => (prevIndex + 1) % quotes.length);
        }, 6000); // Change quote every 5 seconds

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, []);

    return (
        <div>
            <Header />
            <BannerSection />
            <MemberSection />
            <AboutSection />
            <WorkSection />
            <TestimonialSection />
            <div style={quoteStyle}>
                <em>{quotes[quoteIndex]}</em>
            </div>
            <FooterSection/>
        </div>
    );
};

const quoteStyle = {
    position: 'absolute',
    top: '20%',
    left: '90%',
    transform: 'translate(-50%, -20%)',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    color: 'white',
    padding: '20px',
    borderRadius: '5px',
    textAlign: 'center',
    transition: 'opacity 3s ease-in-out',
    opacity: 0.9
};

export default HomePages;
