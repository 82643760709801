import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";
import { setLocalStorage, getFromLocalStorage } from "../utils/local-storage";
import Notify from "../utils/Notify";

const initialState = {
    loading: false,
    current_component:"members"
}

//asynchronous function , action , callback
export const update_component = createAsyncThunk(
    "update_component", async (data, thunkAPI) => {
        const state = thunkAPI.getState()
        return data;
    })


const applicationSlice = createSlice({
    name: "Application",
    initialState,
    extraReducers: (builder) => {
        //events reducers
        builder.addCase(update_component.fulfilled, (state, action) => {
            state.loading = false
            state.current_component = action.payload         
        })
    }
})

export default applicationSlice.reducer
