import client from "./client";

export default {
    async get_user_packages() {
        return client.parseResponse(await client.get('/packages/user-packages'))
    },
    async get_packages() {
        return client.parseResponse(await client.get('/packages'))
    },
    async get_package(id) {
        return client.parseResponse(await client.get('/packages/details',id))
    },

    async checkout(id){
        return client.parseResponse(await client.post('/packages/checkout',id))
    }
}