import React, { useEffect, useState } from 'react';
import PageHeader from '../../component/layout/pageheader';
import { useParams, NavLink } from 'react-router-dom';
import { user } from '../../store/users.store';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from 'nuka-carousel';
import { PersonFill, PeopleFill, CalendarEventFill, HouseFill, Calendar2CheckFill } from 'react-bootstrap-icons';
import { BiLogOut } from 'react-icons/bi';
import { RiFileListLine } from 'react-icons/ri';
import Packages from '../packages';
import Events from '../events';
import Settings from './Settings';
import Invoices from '../invoices';


const Account = () => {
    const id = useParams();
    const dispatch = useDispatch();
    const User = useSelector((state) => state?.users?.userDetails);
    const current_component = useSelector((state) => state?.application?.current_component)
    const [active, setActive] = useState("profile")




    useEffect(() => {
        if (current_component === "member") {
            dispatch(user(id));
        }
    }, []);

    return (
        <div className="profile-page p-4">
            {/* <hr/> */}

            <div className='mb-5'>
                <div className='account-title p-2'>
                    <NavLink to="#" className={`mx-3 ${active=="profile"?'underline':""}`} onClick={() => setActive("profile")}>
                        <PersonFill /><span className="p-1">Profile</span>
                    </NavLink> |
                    <NavLink to="#" className={`mx-3 ${active=="events"?'underline':""}`} onClick={() => setActive("events")}>
                        <CalendarEventFill size={13}/><span className="p-1">My Events</span>
                    </NavLink> |  
                    <NavLink to="#" className={`mx-3 ${active=="packages"?'underline':""}`} onClick={() => setActive("packages")}>
                        <Calendar2CheckFill size={13}/><span className="p-1">My Packages</span>
                    </NavLink> |   
                    <NavLink to="#" className={`mx-3 ${active=="invoices"?'underline':""}`} onClick={() => setActive("invoices")}>
                        <Calendar2CheckFill size={13}/><span className="p-1">Invoices</span>
                    </NavLink> | 
                    <NavLink to="#" className={`mx-3 ${active=="settings"?'underline':""}`}  onClick={() => setActive("settings")}>
                        <Calendar2CheckFill size={13}/><span className="p-1">Preferences</span>
                    </NavLink> |
                    <NavLink to="/logout" className={`mx-3 ${active=="logout"?'underline':""}`} >                   
                        <BiLogOut /><span className="p-1"> Logout</span>
                    </NavLink>
                </div>
                <hr />

            </div>


            <section className={`profile-section ${active == "profile" ? "" : "d-none"}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="profile-cover">

                                {User?.images?.length >= 1 ? (
                                    <Carousel>
                                        {User?.images.map((image, index) => (
                                            <img key={index} src={image.image.file} alt={`Image ${index}`} style={{ maxHeight: '200px' }} />
                                        ))}
                                    </Carousel>
                                ) : (
                                    <Carousel>
                                        <img src="https://images.unsplash.com/photo-1618042164219-62c820f10723?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8dW5zcGFsc2h8ZW58MHx8MHx8fDA%3D" alt="Place holder image" style={{ maxHeight: '500px', width: "100%" }} />
                                    </Carousel>
                                )}

                            </div>

                        </div>
                        <div className="col-lg-8">
                            <div className="profile-information">
                                <div className="profile-name">
                                    <h2>{User?.full_name}</h2>
                                    <p>{User?.email}</p>
                                    <p>{User?.phone_number}</p>
                                    <p>Account Status: {User?.account_status}</p>
                                    <p>Last Active: 2 minutes ago</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className={`profile-section ${active == "packages" ? "" : "d-none"}`}>
                <div className="container">
                    <div className="row">
                        <Packages user={true} />
                    </div>
                </div>
            </section>


            <section className={`profile-section ${active == "invoices" ? "" : "d-none"}`}>
                <div className="container">
                    <div className="row">
                        <Invoices/>                        
                    </div>
                </div>
            </section>




            <section className={`profile-section ${active == "events" ? "" : "d-none"}`}>
                <div className="container">
                    <div className="row">
                        <Events user={true} />

                    </div>
                </div>
            </section>

            <section className={`profile-section ${active == "settings" ? "" : "d-none"}`}>
                <div className="container">
                    <div className="row">
                        <Settings/>

                    </div>
                </div>
            </section>

        </div>
    );
}

export default Account;
