import React, { Component, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { update_component } from '../../store/application.store';
import { useDispatch ,useSelector} from 'react-redux';

const navigate = useNavigate;

const PageHeader =(props)=> {
    const dispatch = useDispatch();
    const [crumb,setCrumb]=useState("members")
    const user_pref = useSelector((state) => state?.users?.accountDetails?.preferences)

    const {title} = props;
    const {show} = props;

    const handleClick =(value)=>{
        setCrumb(value)
        dispatch(update_component(value))

    }


    return (
        <section className={`page-header-section sticky ${show ? '':'d-none'}`}  style={{ backgroundImage: "url(/assets/images/page-header.jpg)", position: "sticky", top: 0,zIndex:"1000" }}>
            <div className="container">
                <div className="page-header-content" >
                    <div className="page-header-inner">
                        <div className="page-title px-3">

                        <ol className="breadcrumb p-2">
                            {/* <li className='mx-2' onClick={()=>navigate("/")}><Link to="/">Home</Link></li> |   */}
                            <li className={`mx-2 ${user_pref?.online_dating ? "":"d-none"} ${title=="members"?'underline':""}`}><NavLink onClick={()=>handleClick("members")}>Online | </NavLink></li>  
                            {/* <li className={`mx-2 ${title=="offline"?'underline':""}`}><NavLink onClick={()=>handleClick("offline")}>Offline</NavLink></li> |  */}
                            <li className={`mx-2 ${user_pref?.online_dating ? "":"d-none"} ${title=="over60"?'underline':""}`}><NavLink onClick={()=>handleClick("over60")}>Over 60 | </NavLink></li> 


                            <li className={`mx-2 ${title=="events"?'underline':""}`}><NavLink onClick={()=>handleClick("events")}>Social</NavLink></li> | 
                            <li className={`mx-2 ${title=="packages"?'underline':""}`}><NavLink onClick={()=>handleClick("packages")}>Packages</NavLink></li> |
                            {/* <li className={`mx-2 ${title=="invoices"?'underline':""}`}><NavLink onClick={()=>handleClick("invoices")}>Invoices</NavLink></li> | */}
                            <li className={`mx-2 ${title=="account"?'underline':""}`}><NavLink onClick={()=>handleClick("account")}>My Account</NavLink></li>
                        </ol>
                            <h6>MatchKenya {title}</h6>
                        </div>
                        {/* <ol className="breadcrumb mb-5">
                                <li className="active mx-2">{title}</li>
                        </ol> */}
                    </div>
                </div>
            </div>
        </section>
    );
    
}

export default PageHeader;