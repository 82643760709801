import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";
import { setLocalStorage, getFromLocalStorage } from "../utils/local-storage";
import Notify from "../utils/Notify";

const initialState = {
    loading: false,
    packages: {},
    package: {}
}

//asynchronous function , action , callback
export const getPackages = createAsyncThunk(
    "packages", async (data, thunkAPI) => {
        const state = thunkAPI.getState()
        try {
            return await api.get_packages()
        } catch (e) {
            console.log(e)

            return thunkAPI.rejectWithValue(e.response.data)
        }
    })

export const getUserPackages = createAsyncThunk(
    "user-packages", async (data, thunkAPI) => {
        const state = thunkAPI.getState()
        try {
            return await api.get_user_packages()
        } catch (e) {
            console.log(e)

            return thunkAPI.rejectWithValue(e.response.data)
        }
    })

export const getPackage = createAsyncThunk(
    "package", async (id, thunkAPI) => {
        const state = thunkAPI.getState()
        try {
            return await api.get_package(id)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    })


export const checkout = createAsyncThunk(
    "checkout", async (data, thunkAPI) => {
        const state = thunkAPI.getState()
        try {
            return await api.checkout(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    })

const packageSlice = createSlice({
    name: "Packages",
    initialState,
    extraReducers: (builder) => {
        //events reducers
        builder.addCase(getPackages.fulfilled, (state, action) => {
            state.loading = false
            state.packages = action.payload
            
        })

        //if a user is not successfully logged in
        builder.addCase(getPackages.rejected, (state, action) => {
            state.loading = false
            console.log(action.payload)
        })

        builder.addCase(getPackages.pending, (state, action) => {
            state.loading = true;
        })


        //User Packages
        builder.addCase(getUserPackages.fulfilled, (state, action) => {
            state.loading = false
            state.user_packages = action.payload
            
        })

        //if a user is not successfully logged in
        builder.addCase(getUserPackages.rejected, (state, action) => {
            state.loading = false
            console.log(action.payload)
        })

        builder.addCase(getUserPackages.pending, (state, action) => {
            state.loading = true;
        })




        //Signup reducers
        builder.addCase(getPackage.pending, (state, action) => {
            state.loading = true;
            state.package = action.payload

        })

        builder.addCase(getPackage.fulfilled, (state, action) => {
            state.loading = false
            state.package = action.payload
        })

        builder.addCase(getPackage.rejected, (state, action) => {
            state.loading = false
            Notify({ status: 421, message: action?.payload?.error })

        })


        //forgot paswword reducers
        builder.addCase(checkout.pending, (state, action) => {
            state.loading = true

        })

        builder.addCase(checkout.fulfilled, (state, action) => {
            state.loading = false
            state.authenticated = true

            // setTimeout(function(){
            //     Notify({ status: 200, message: "Check your email address for the OTP" })
            // },1000)
            
        })

        builder.addCase(checkout.rejected, (state, action) => {
            Notify({ status: 421, message: action?.payload?.error })
        })

    }
})

export default packageSlice.reducer
