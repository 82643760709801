import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signupUser } from '../../store/users.store';
import Header from '../../component/layout/header';
import PageHeader from '../../component/layout/pageheader';
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field ,ErrorMessage} from "formik";
import { ToastContainer } from "react-toastify"
import Loader from '../../utils/Loader';
import { upload } from '../../store/users.store';
import { BiArrowBack } from 'react-icons/bi';

import * as Yup from 'yup';




const title = "Signup To MatchKenya";
const SignInPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state) => state.users.loading);
    const [signUpDetails, setSignUpDetails] = useState({});
    const [activeForm, SetActiveForm] = useState(false);

    const [file, setFile] = useState(null);
    const [file2, setFile2] = useState(null);
    const [file3, setFile3] = useState(null);
    const [file4, setFile4] = useState(null);


    const user_images = useSelector((state) => state?.users?.upload?.uploaded_files);

    const handleFormSubmit = (values, { setSubmitting }) => {
        values['user_images'] = [];
        setSignUpDetails(values);

        SetActiveForm(true);
    }

    const validationSchema = Yup.object().shape({
        password: Yup.string()
          .required('Password is required'),
        password_confirm: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Confirm Password is required'),
      });
      


    const handleImageUpload = async () => {
   
        if (!file) {
            alert("Please select an Image");
            return;
        }
        const formData = new FormData();
        formData.append("file", file);
        formData.append("file", file2);
        formData.append("file", file3);
        formData.append("file", file4);


        dispatch(upload(formData)).then((result) => {
            if (result?.payload?.details === "Success") {
                console.log("The result is", result);
                setSignUpDetails({ ...signUpDetails, user_images: user_images });

                dispatch(signupUser(signUpDetails)).then((result) => {
                    if (result?.payload?.message === "success") {
                        navigate("/login")
                    } else {
                        SetActiveForm(false)
                    }
                })
            }
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!file) {
            console.error("No file selected");
            return;
        }
        const formData = new FormData();
        formData.append("file", file);

        dispatch(upload(formData)).then((result) => {
            if (result?.payload?.successful === 1) {
                console.log("The result is", result);
                // navigate("/signup")
                signUpDetails['user_images'] = user_images;
                setSignUpDetails(signUpDetails['user_images'] = user_images);

                dispatch(signupUser(signUpDetails)).then((result) => {
                    if (result?.payload?.message === "success") {
                        navigate("/auth")
                    } else {
                        //Display the signup form
                        SetActiveForm(false)
                    }
                })
            }
        });
    };

    return (
        <div className='h-100'>
            <Header />
            <PageHeader title={'Sign Up'} show={true} />
            <div className={`mt-5 ${activeForm ? 'd-none' : ''}`}>
                <div className="container w-100">
                    <div className="p-4 w-100">
                        <h3 className="title">{title}</h3>
                        <Formik
                            initialValues={{
                                email: "",
                                first_name: "",
                                surname: "",
                                password: "",
                                password_confirm: "",
                                phone_number: "",
                                referall_code: ""
                            }}

                            onSubmit={handleFormSubmit}
                        >
                            {({ errors, touched }) => (
                            <Form className="account-form row">
                            
                                <div className="form-group col-4">
                                    <Field
                                        type="text"
                                        name="first_name"
                                        id="item02"
                                        placeholder="First Name *"
                                        required
                                    />
                                </div>
                                <div className="form-group col-4">
                                    <Field
                                        type="text"
                                        name="surname"
                                        id="item03"
                                        placeholder="Surname *"
                                        required
                                    />
                                </div>

                                <div className="form-group col-4">
                                    <Field
                                        type="email"
                                        name="email"
                                        id="item01"
                                        placeholder="Your email *"
                                        required
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <Field
                                        type="password"
                                        name="password"
                                        id="item04"
                                        className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
                                        placeholder="Password *"
                                        required

                                    />
                                </div>
                                <div className="form-group col-6">
                                    <Field
                                        type="password"
                                        name="password_confirm"
                                        id="item05"
                                        className={`form-control ${touched.password_confirm && errors.password_confirm ? 'is-invalid' : ''}`}
                                        placeholder="Password Confirm *"
                                        required
                                    />
                                        <ErrorMessage name="password_confirm" component="div" className="invalid-feedback" />
                                </div>
                                <div className="form-group">
                                    <Field
                                        type="text"
                                        name="phone_number"
                                        id="item06"
                                        placeholder="Phone number *"
                                        required
                                    />
                                </div>

                          
                       
                                <div class="form-group">
                                    <Field id="dob" type="date" name="dob" class="form-control" required />
                                </div>
                            
                                {/* <div className="form-group">
                                    <Field
                                        type="text"
                                        name="referall_code"
                                        id="item07"
                                        placeholder="Referral Code"
                                    />
                                </div> */}
                                <div className="form-group">
                                    <div className='mx-auto text-center mt-2'>{loading ? <Loader /> : ""} </div>

                                    <button className="d-block lab-btn" disabled={loading}><span>Next</span></button>
                                </div>
                            </Form>
                               )}
                        </Formik>
                        <div className="account-bottom">
                            <span className="d-block cate pt-10">Are you a member? <Link to="/login">Login</Link></span>
                        </div>
                    </div>
                </div>
            </div>



            <div className={`login-section ${activeForm ? '' : 'd-none'}`}>
                <div className="container">
                <BiArrowBack className='rounded-pill' onClick={()=>SetActiveForm(false)} size={25}  style={{cursor:"pointer"}}/> 
                    <div className="account-wrapper">

                        <h3 className="title">Transform pixels into memories: upload Your Best Pictures </h3>

                        <form onSubmit={handleSubmit} className="upload-form">
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-12 col-md-6'>
                                        <div className='file-input-group'>
                                            <label htmlFor="file1" className="file-label">
                                                <span className="file-icon h1 mx-4">&#x1F4F7;</span>
                                                {file && (
                                                    <div className="shadow row w-100">
                                                        <div className='p-3'>
                                                            <img src={URL.createObjectURL(file)} alt="Selected" className="w-100 selected-preview" />
                                                        </div>
                                                    </div>
                                                )}
                                                <input type="file" id="file1"
                                                    name="file1"
                                                    className="mt-4"
                                                    onChange={(e) => setFile(e.target.files[0])}
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <div className='col-12 col-md-6'>
                                        <div className='file-input-group'>
                                            <label htmlFor="file2" className="file-label">
                                                <span className="file-icon h1 mx-4">&#x1F4F7;</span>
                                                {file2 && (
                                                    <div className="shadow row w-100">
                                                        <div className='p-3'>
                                                            <img src={URL.createObjectURL(file2)} alt="Selected" className="w-100 selected-preview" />
                                                        </div>
                                                    </div>
                                                )}
                                                <input type="file" id="file2"
                                                    name="file2"
                                                    className="mt-4"
                                                    onChange={(e) => setFile2(e.target.files[0])}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12 col-md-6'>
                                        <div className='file-input-group'>
                                            <label htmlFor="file3" className="file-label">
                                                <span className="file-icon h1 mx-4">&#x1F4F7;</span>
                                                {file3 && (
                                                    <div className="shadow row w-100">
                                                        <div className='p-3'>
                                                            <img src={URL.createObjectURL(file3)} alt="Selected" className="w-100 selected-preview" />
                                                        </div>
                                                    </div>
                                                )}
                                                <input type="file" id="file3"
                                                    name="file3"
                                                    className="mt-4"
                                                    onChange={(e) => setFile3(e.target.files[0])}
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <div className='col-12 col-md-6'>
                                        <div className='file-input-group'>
                                            <label htmlFor="file4" className="file-label">
                                                <span className="file-icon h1 mx-4">&#x1F4F7;</span>
                                                {file4 && (
                                                    <div className="shadow row w-100">
                                                        <div className='p-3'>
                                                            <img src={URL.createObjectURL(file4)} alt="Selected" className="w-100 selected-preview" />
                                                        </div>
                                                    </div>
                                                )}
                                                <input type="file" id="file4"
                                                    name="file4"
                                                    className="mt-4"
                                                    onChange={(e) => setFile4(e.target.files[0])}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <button type="submit"
                                    onClick={()=>handleImageUpload()}
                                    disabled={loading}
                                    className="d-block btn-outline-success btn w-100 mt-4 px-1 py-2">
                                    <strong>Signup</strong>
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

            <ToastContainer />
        </div>
    );
};

export default SignInPage;
