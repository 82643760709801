import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";
import Notify from "../utils/Notify";

const initialState = {
    loading: false,
}

export const like_user = createAsyncThunk(
    "user/like"  ,async (id,thunkAPI)=>{
        const state = thunkAPI.getState()
        try {
            return await api.likeUser(id)        
        }catch(e){
            return thunkAPI.rejectWithValue(e.response.data)        
        }
    } 
)

export const user_likes = createAsyncThunk(
    "user-likes"  ,async (_,thunkAPI)=>{
        const state = thunkAPI.getState()
        try {
            return await api.userLikes()        
        }catch(e){
            return thunkAPI.rejectWithValue(e.response.data)        
        }
    } 
)

export const add_user_preference = createAsyncThunk(
    "user-preference"  ,async (data,thunkAPI)=>{
        const state = thunkAPI.getState()
        try {
            return await api.addUserPrefference(data)        
        }catch(e){
            return thunkAPI.rejectWithValue(e.response.data)        
        }
    } 
)

export const user_matches = createAsyncThunk(
    "user-matches"  ,async (_,thunkAPI)=>{
        const state = thunkAPI.getState()
        try {
            return await api.userMatches()        
        }catch(e){
            return thunkAPI.rejectWithValue(e.response.data)        
        }
    } 
)


const datingSlice = createSlice({
    name: "dating",
    initialState,
    extraReducers: (builder) => {
        //Like user reducers
        builder.addCase(like_user.fulfilled, (state, action) => {
            state.loading = false
            state.events = action.payload     
        })

        builder.addCase(like_user.rejected, (state, action) => {
            state.loading = false
            Notify({ status: 421, message: action?.payload?.errors })
        })

        builder.addCase(like_user.pending, (state, action) => {
            state.loading = true;
        })


        //User likes reducers
        builder.addCase(user_likes.fulfilled, (state, action) => {
            state.loading = false
            state.user_likes = action.payload            
        })

        builder.addCase(user_likes.rejected, (state, action) => {
            state.loading = false
            Notify({ status: 421, message: action?.payload?.errors })
        })

        builder.addCase(user_likes.pending, (state, action) => {
            state.loading = true;
        })



        //User Prefferences reducer
        builder.addCase(add_user_preference.fulfilled, (state, action) => {
            state.loading = false            
        })

        
        builder.addCase(add_user_preference.rejected, (state, action) => {
            state.loading = false
            Notify({ status: 421, message: action?.payload?.errors })
        })

        builder.addCase(add_user_preference.pending, (state, action) => {
            state.loading = true;
        })


        //User mathces reducer
        builder.addCase(user_matches.fulfilled, (state, action) => {
            state.loading = false
            state.user_matches = action.payload
            
        })

        builder.addCase(user_matches.rejected, (state, action) => {
            state.loading = false
            Notify({ status: 421, message: action?.payload?.errors })
        })

        builder.addCase(user_matches.pending, (state, action) => {
            state.loading = true;
        })


    }
})

export default datingSlice.reducer
