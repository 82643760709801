import client from "./client";
import auth_client from "./auth_client";

export default {
    async events() {
        return client.parseResponse(await auth_client.get('/events'))
    },

    async event(id){
        return client.parseResponse(await client.get('/events/details',id))
    },

    async eventCheckout(data){
        return client.parseResponse(await client.post('/events/checkout',data))
    },
}   