import React, { useEffect } from 'react';
import PageHeader from '../component/layout/pageheader';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getPackages, getPackage, getUserPackages, checkout } from '../store/packages.store';


const Packages = (props) => {
    const user_prof = props?.user
    const dispatch = useDispatch();
    const navigate = useNavigate();
    var packages = useSelector((state) => state?.packages?.packages?.results);
    var user_packages = useSelector((state) => state?.packages?.user_packages?.results);
    const packageDetail = useSelector((state) => state?.packages?.package);
    const current_component = useSelector((state) => state?.application?.current_component)

    if (user_prof) {
        packages = user_packages
    }


    useEffect(() => {
        if (current_component === "packages") {
            dispatch(getPackages());
        }
    }, [current_component]);

    useEffect(() => {
        if (user_prof) {
            dispatch(getUserPackages());
        }

    }, [user_prof])

    const handleDetails = (id) => {
        dispatch(getPackage({ "id": id }));
    }

    const handleGet = (id) => {
        dispatch(checkout({ "id": id }));
        // navigate("/invoices");
        alert("Package Added to Invoices succesfully....")
    }



    return (
        <div>
            {/* <PageHeader title={'Packages'}  show={true}/> */}
            <section className="member-page-section">
                <div className="container">
                    <ul className="member-wrapper member-info mb-4">
                        <li className="all-member">
                            <p>{user_prof ? "User packages" : "All packages"}</p>

                        </li>
                    </ul>


                    <div className="row">
                        {packages?.map((pack_age) => (
                            <div className="col-md-6 col-lg-4 col-xl-3 mb-4 mt-4" key={pack_age.id}>
                                <div className=" package-card shadow-lg" style={{ border: "0", borderRadius: "15px", overflow: "hidden" }}>
                                    <div className="card-body" style={{ background: "linear-gradient(135deg, #210053 0%, #3a0a8a 100%)", color: "white", opacity: "0.95", padding: "20px" }}>
                                        <h5 className="card-title" style={{ fontWeight: "bold", fontSize: "1.25rem", marginBottom: "10px" }}>{pack_age.name}</h5>
                                        <p className="card-text" style={{ fontSize: "1rem", marginBottom: "20px" }}>Charges: Ksh {pack_age.amount}</p>
                                        <p className='card-text'>Duration : {pack_age?.duration}</p>

                                        <div className="p-2">
                                            <button
                                                className="btn btn-outline-danger btn-details w-100"
                                                data-bs-toggle="modal"
                                                data-bs-target="#packageModal"
                                                onClick={() => handleDetails(pack_age.id)}
                                                style={{
                                                    flex: "1",
                                                    marginRight: "10px",
                                                    fontSize: "1rem",
                                                    fontWeight: "bold",
                                                    borderRadius: "30px",
                                                    borderColor: "#28a745",
                                                    color: "#28a745",
                                                    transition: "all 0.3s ease",
                                                }}
                                                onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#28a745", e.currentTarget.style.color = "white")}
                                                onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent", e.currentTarget.style.color = "#28a745")}
                                            >
                                                View Details
                                            </button>
                                            <button
                                                className={`btn btn-info mt-2 w-100 btn-get  ${user_prof ? "d-none" : ""}`}
                                                onClick={() => handleGet(pack_age.id)}
                                                style={{
                                                    flex: "1",
                                                    fontSize: "1rem",
                                                    fontWeight: "bold",
                                                    borderRadius: "30px",
                                                    backgroundColor: "#28a745",
                                                    borderColor: "#28a745",
                                                    transition: "all 0.3s ease",
                                                }}
                                                onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#218838")}
                                                onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#28a745")}
                                            >
                                                Subscribe Now
                                            </button>
                                        </div>





                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>





                </div>
            </section>

            {/* Package Modal */}
            <div className="modal fade" id="packageModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-dark" id="exampleModalLabel">{packageDetail?.name}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p className='text-dark'>Charges: {packageDetail?.amount}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Packages;
