import client from "./client";

export default {
    async invoices() {
        return client.parseResponse(await client.get('/invoices/user-invoices'))
    },
    async invoice(id) {
        return client.parseResponse(await client.get('/invoices/details',id))
    },

}