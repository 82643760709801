import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { users, accountDetails } from '../store/users.store';
import PageHeader from '../component/layout/pageheader';
import Deck from './Deck';
import Loader from '../utils/Loader';
import { Tab, Tabs } from 'react-bootstrap';
import Invoices from './invoices';
import Events from './events';
import Packages from './packages';
import ProfilePage from './profile';
import Account from './Account/Account';
import { PersonFill } from 'react-bootstrap-icons';
import { user_matches, user_likes } from '../store/dating.store';

const Members = () => {
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    const user = useSelector((state) => state?.users?.accountDetails);
    const [key, setKey] = useState('matches');
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(screenWidth <= 768);
    const [small,setSmall] = useState(false)
    const current_component = useSelector((state) => state?.application?.current_component)

    const likes = useSelector((state)=>state?.dating?.user_likes?.results)
    const matches = useSelector((state)=>state?.dating?.user_matches?.results)
    // const loading = useSelector((st


    useEffect(()=>{
        dispatch(user_matches())
        dispatch(user_likes())

    },[])


    useEffect(() => {
        if(current_component==="members"){
            dispatch(users()).then((result) => {
                console.log(result.payload.results.length)
                if (result.payload.results.length >= 1) {
                    setLoaded(true);
                    console.log("loaded. ...")
                }else if(result.payload.results.length < 1){
                    setLoaded(true)
                }
            });

        dispatch(accountDetails());
        }       
    }, [current_component]);

    useEffect(() => {
        // Function to update screenWidth and isMobile
        const updateScreenWidth = () => {
          const newWidth = window.innerWidth;
          setScreenWidth(newWidth);
          setIsMobile(newWidth <= 768);
        };

        if(isMobile){
            setSmall(true)
            console.log("It is mobile")
        }else if(!isMobile){
            setSmall(false)
        }
    
        // Attach event listener for window resize
        window.addEventListener('resize', updateScreenWidth);
    
        // Call the update function once to get the initial screen width and set isMobile
        updateScreenWidth();
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', updateScreenWidth);
        };
      }, [screenWidth]);

    return (
        <div className='row'>
            <div className='col-2 account-members-offcanvas'>

            <div class="offcanvas offcanvas-start show account-members" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                
                    <div  className="account-details text-center col-12 py-4 my-2">          
                        <h6 className="user-name text-start d-block "><PersonFill className='mr-3' color='purple' size={35}/> <span className='mx-auto'>{user?.full_name}</span></h6>                
                    </div>
                        
                    <div className="offcanvas-body mt-2">
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="matches" title="Matches" className='tab-btn'>
                                <hr/>
                                
                                <div class="tab-content row" id="matches-content">
                                    {matches?.length >= 1 ?(matches.map((match)=>(
                                        <div class="col-6">
                                           <img src="https://cdn.pixabay.com/photo/2016/03/31/17/33/avatar-1293744_640.png" alt="Match 1"/>
                                           <div class="card-body">
                                               <p class="card-title"><small style={{fontSize:".6em"}}>{match.first_name}</small></p>
                                           </div>
                                       </div>
                                    ))):<div>There are no matches</div>}                             
                            
                                </div>

                            </Tab>
                            {/* <Tab eventKey="likes" title="Likes" className='tab-btn'>
                                <hr/>
                                
                                    <div class="tab-content" id="likes-content">
                                        {likes?.length >=1 ? (likes.map((like)=>(<div class=" row">
                                            <div className='col-6'>
                                                <img src="https://via.placeholder.com/150" alt="Like 1" style={{height:"2cm"}}/>
                                            </div>
                                            <div class="card-body col-6">
                                                <p class="card-title"><small>{like.first_name}</small></p>
                                                <hr/>
                                            </div>
                                        </div>))):<div>No likes found</div>}

                                    </div>

                                
                            </Tab> */}


                            <Tab eventKey="messages" title="Messages" className='tab-btn'>
                                <hr/>
                                <p>Messages go here...</p>                  
                            </Tab>
                        </Tabs>
                    </div>
                </div>

            </div>
            <div className={`home-components ${small ? 'col-12' : 'col-10'}`}>
                <PageHeader title={current_component}  show={true}/>
                <div className={`container ${current_component == "members" || current_component == "offline"|| current_component == "over60"  ? "":"d-none"}`}>
                    <div className='row'>
                        <div className='col-4'></div>
                        <div className='col-12 mx-auto'>{loaded ? <Deck /> : <Loader/>}</div>
                    </div>
                </div>

                <div className={`container ${current_component == "invoices" ? "":"d-none"}`}>
                    <div className='row'>
                        <div className='col-4'></div>
                        <div className='col-12 mx-auto'><Invoices/></div>
                    </div>
                </div>

                <div className={`container  ${current_component == "events" ? "":"d-none"}`}>
                    <div className='row'>
                        <div className='col-4'></div>
                        <div className='col-12 mx-auto'><Events/> </div>
                    </div>
                </div>

                <div className={`container ${current_component == "packages" ? "":"d-none"}`}>
                    <div className='row'>
                        <div className='col-4'></div>
                        <div className='col-12 mx-auto'><Packages/> </div>
                    </div>
                </div>

                <div className={`container ${current_component == "member" ? "":"d-none"}`}>
                    <div className='row'>
                        <div className='col-4'></div>
                        <div className='col-12 mx-auto'><ProfilePage/> </div>
                    </div>
                </div>

                <div className={`container ${current_component == "account" ? "":"d-none"}`}>
                    <div className='row'>
                        <div className='col-4'></div>
                        <div className='col-12 mx-auto'><Account/> </div>
                    </div>
                </div>

                
            </div>
        </div>
    );
};

export default Members;
