import React, {useState, Component } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Slider from 'rc-slider';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import localityData from '../../data/location_data.json';


const bannertitle = "Introducing MatchKenya";
const bannerdesc = <p>Serious Dating With <strong>MatchKenya </strong> Your Perfect Match is Just a Click Away.</p>;

const BannerSection = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleFormSubmit = (values) => {
        console.log("The values are", values)
        // dispatch(save_prefs(values))
    }

    const cityOptions = localityData.map(locality => ({
        value: locality.fields.code,
        label: locality.fields.name
    }));
    
    const CitySearch = ({ field, form }) => {
        const [selectedOption, setSelectedOption] = useState(null);

        const customStyles = {
            control: (provided) => ({
                ...provided,
                backgroundColor: '',
                color: 'white',
                border:"1px solid grey",
                boxShadow: 'none' // optional: remove box shadow,

            }),
            menu: (provided) => ({
                ...provided,
                backgroundColor: 'black',
                color: 'dark'
            }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? 'blue' : 'black',
                color: state.isSelected ? 'white' : 'dark',
                '&:hover': {
                    backgroundColor: 'gray'
                }
            }),
            singleValue: (provided) => ({
                ...provided,
                color: 'dark'
            }),
            placeholder: (provided) => ({
                ...provided,
                color: 'dark'
            })
        };
    
        const handleChange = (option) => {
            setSelectedOption(option);
            form.setFieldValue(field.name, option ? option.value : '');
        };
    
        return (
            <div className="city text-dark">
                <label className="left">City</label>
                <Select
                    className="right text-dark "
                    id=""
                    name="city"
                    placeholder="Your location.."
                    value={selectedOption}
                    onChange={handleChange}
                    options={cityOptions}
                    styles={customStyles} //
                />
            </div>
        );
    };
    


    return (
        <section className="banner-section">
            <div className="container">
                <div className="section-wrapper">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="banner-content">
                                <div className="intro-form">
                                    <div className="intro-form-inner">
                                        <h3>{bannertitle}</h3>
                                        {bannerdesc}

                                        <Formik
                                            initialValues={{
                                                gender: '',
                                                lookingFor: '',
                                                ageStart: 18,
                                                ageEnd: 100,
                                                minAgeStart: 18,
                                                minAgeEnd: 100,
                                                city: '',
                                                onlineDating: ''
                                            }}
                                            onSubmit={handleFormSubmit}
                                        >
                                            {({ values, setFieldValue }) => (
                                                <Form className="banner-form">
                                                    <div className="gender">
                                                        <label className="left">I am a </label>
                                                        <div className="custom-select right">
                                                            <Field as="select" name="gender" id="gender" className="">
                                                                <option value="">Select Gender</option>
                                                                <option value="MALE">Male</option>
                                                                <option value="FEMALE">Female</option>
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className="person">
                                                        <label className="left">Looking for a</label>
                                                        <div className="custom-select right">
                                                            <Field as="select" name="lookingFor" id="lookingFor" className="">
                                                                <option value="">Select Gender</option>
                                                                <option value="MALE">Male</option>
                                                                <option value="FEMALE">Female</option>
                                                            </Field>
                                                        </div>
                                                    </div>

                                                    <div className="city">
                                                        {/* <label className="left">City</label> */}
                                                        <Field className="right form-control" type="text" 
                                                        component={CitySearch}   name="city" id="city" placeholder="Your City Name.." />
                                                    </div>

                                                    <div className="age">
                                                        <label className="left">Age Range</label>
                                                        <div className="right d-flex flex-column">
                                                            <Slider
                                                                range
                                                                min={18}
                                                                max={100}
                                                                value={[values.ageStart, values.ageEnd]}
                                                                onChange={([ageStart, ageEnd]) => {
                                                                    setFieldValue('ageStart', ageStart);
                                                                    setFieldValue('ageEnd', ageEnd);
                                                                }}
                                                                trackStyle={[{ backgroundColor: '#ff0844' }]}
                                                                handleStyle={[
                                                                    { borderColor: '#ff0844', backgroundColor: '#ff0844' },
                                                                    { borderColor: '#ff0844', backgroundColor: '#ff0844' }
                                                                ]}
                                                                railStyle={{ backgroundColor: '#ddd' }}
                                                            />
                                                            <div className="d-flex justify-content-between mt-2">
                                                                <span>{values.ageStart}</span>
                                                                <span>{values.ageEnd}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <label className="form-check-label">
                                                        <Field type="checkbox" name="over60" value="over60" className="form-check-input" />
                                                        <span className='mx-2'>Over 60</span>
                                                    </label>

                                                    <div className="online-dating mt-3">
                                                        <label className="left">Dating Preference</label>
                                                        <div className="right d-flex justify-content-around">
                                                            <label className="form-check-label">
                                                                <Field type="radio" name="onlineDating" value="online" className="form-check-input" />
                                                                <span className='mx-2'>Online Dating</span>
                                                            </label>
                                                            <label className="form-check-label">
                                                                <Field type="radio" name="onlineDating" value="offline" className="form-check-input" />
                                                                <span className='mx-2'>Offline Dating</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <button type="submit"
                                                        //  onClick={()=>navigate("/signup")}
                                                        className='mt-3'>Find Your Partner</button>
                                                </Form>
                                            )}
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 vh-100" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <div className="banner-thumb" style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100%"}}>
                                <img src="assets/images/banner/011.png" alt="img"  style={{maxHeight:"150%",maxWidth:"100%",objectFit:"contain", marginTop:"-5cm",
                                    borderRadius:"300px"

                                }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="all-shapes">
                <img src="assets/images/banner/banner-shapes/01.png" alt="shape" className="banner-shape shape-1" />
                <img src="assets/images/banner/banner-shapes/02.png" alt="shape" className="banner-shape shape-2" />
                <img src="assets/images/banner/banner-shapes/03.png" alt="shape" className="banner-shape shape-3" />
                <img src="assets/images/banner/banner-shapes/04.png" alt="shape" className="banner-shape shape-4" />
                <img src="assets/images/banner/banner-shapes/05.png" alt="shape" className="banner-shape shape-5" />
                <img src="assets/images/banner/banner-shapes/06.png" alt="shape" className="banner-shape shape-6" />
                <img src="assets/images/banner/banner-shapes/07.png" alt="shape" className="banner-shape shape-7" />
                <img src="assets/images/banner/banner-shapes/08.png" alt="shape" className="banner-shape shape-8" />
            </div>
        </section>
    );
}

export default BannerSection;