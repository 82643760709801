
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import React, { useEffect, useCallback, Suspense } from "react";
import HomePages from './pages/home';
import Events from './pages/events';
import Event from './pages/Event';

import Members from './pages/members';
import Packages from './pages/packages';
import Invoices from "./pages/invoices";
import './App.css';
import './assets/css/responsive.css'

import ProfilePage from './pages/profile';
import LoginPage from './pages/auth/login';
import SignInPage from './pages/auth/signin';
import PricingPlanPage from './pages/pricingplan';
import ForeZeroPage from './pages/forezero';
import ContactPage from './pages/contact';
import { useDispatch } from 'react-redux';
import { getFromLocalStorage } from './utils/local-storage';
import ProtectedRoute from './utils/ProtectedRoute';
import { logout } from './store/users.store';
import Upload from "./pages/auth/Upload";
import Invoice from "./pages/Invoice";
import Loader from "./utils/Loader";
import BottomBar from "./component/layout/BottomBar";
import TermsAndConditions from "./pages/TermsAndConditions";



function App() {
	const Logout = () => {
		console.log("Logout hit")
		const dispatch = useDispatch();
		dispatch(logout())
		let navigate = useNavigate();
		const out = useCallback(() => {
			localStorage.clear();
			window.location.href = "/"
		}, [navigate]);

		useEffect(() => {
			out();
		}, [out]);
		return null;
	};

	const token = getFromLocalStorage('token');
	return (

		<div> 
			<BrowserRouter>
				<Suspense fallback={<Loader/>}>
					<Routes>
						<Route path="/" element={token ? <Members/> : <HomePages />} />
						<Route path="/events" element={<ProtectedRoute><Events show={true}/></ProtectedRoute>} />
						<Route path="/members" element={<ProtectedRoute><Members /></ProtectedRoute>} />
						<Route path="/invoices" element={<ProtectedRoute><Invoices /></ProtectedRoute>} />

						<Route path="/packages" element={<ProtectedRoute><Packages /></ProtectedRoute>} />

						<Route path="/profile/:id" element={<ProfilePage />} />
						<Route path="/event/:id" element={<Event />} />
						<Route path="/invoice/:id" element={<Invoice/>} />


						<Route path="login" element={<LoginPage />} />
						<Route path="signup" element={<SignInPage />} />
						<Route path="/upload" element={<Upload />} />

						<Route path="pricing" element={<PricingPlanPage />} />
						<Route path="*" element={<ForeZeroPage />} />
						<Route path="contact" element={<ContactPage />} />
						<Route path="terms-conditions" element={<TermsAndConditions />} />

						<Route path={"/logout"} element={<Logout />} />

					</Routes>
					
					<div className={`parent-container ${token ? '':'d-none'}`} style={{position:"absolute", top:"100vh"}}>
						<div
							className="position-absolute mx-auto position-fixed bottom-0 start-50 translate-middle-x"
							style={{ width: "100%", maxWidth: "450px" }}>
							<BottomBar current={"home"}/>
						</div>
					</div>
				</Suspense>
			</BrowserRouter>
		</div>
	);
}

export default App;
