import React, { Component } from 'react';
import Header from '../component/layout/header';
import 'bootstrap/dist/css/bootstrap.min.css';

class TermsAndConditions extends Component {
    render() {
        return (
            <div>
                <Header />
                <div className="contact-section">
                    <div className="contact-top padding-tb aside-bg padding-b">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <article className="contact-form-wrapper">
                                        <div className="contact-form">
                                            <h4>Terms And Conditions</h4>
                                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                            1. THE AGREEMENT
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <ul>
                                                                <li>1.1 This agreement sets out the complete terms and conditions (hereinafter called “Terms and Conditions”) which shall be applicable to the Matchkenya  (hereinafter referred to as MatchKenya) a dating platform.</li>
                                                                <li>1.2 These Terms and Conditions and any amendments or variations thereto take effect on their date of publication.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                            2. DEFINITION OF TERMS
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                        <ul className="text-start">
                                                            <li>2.1 Member means any individual who has registered and created an account on the dating platform.</li>
                                                            <li>2.2 Support Team means the customer service team responsible for assisting members with their queries and concerns.</li>
                                                            <li>2.3 Device includes your mobile phone, tablet, computer, or any other equipment that enables you to access the online dating platform.</li>
                                                            <li>2.4 Profile means the personal information and photos that a member submits to the dating platform.</li>
                                                            <li>2.5 Services mean the various matchmaking and communication tools offered by the dating platform to facilitate connections between members.</li>
                                                            <li>2.6 Subscription means the period for which a member has paid to access premium features of the dating platform.</li>
                                                            <li>2.7 Interaction means any form of communication or contact between members facilitated by the dating platform.</li>
                                                            <li>2.8 Privacy Agreement means the legal, valid, binding, and enforceable obligations regarding the use and protection of members' personal information.</li>
                                                            <li>2.9 Content means any text, images, videos, or other media that members upload or share on the dating platform.</li>
                                                            <li>2.10 Message means a short text communication transmitted between members via the dating platform.</li>
                                                            <li>2.11 Platform means the dating site's electronic system and communication tools enabling members to interact with each other.</li>
                                                            <li>2.12 Fees include subscription fees, transaction fees, and any other charges payable for the use of the services as published by the dating platform on its website or by such other means as it shall in its sole discretion determine. Fees are subject to change at any time at the platform's sole discretion.</li>
                                                            <li>2.13 We, our, and us mean the dating platform and include its successors and assigns.</li>
                                                            <li>2.14 You and your mean the member and include the personal representatives of the member.</li>
                                                            <li>2.15 Terms and Conditions mean these Terms and Conditions governing the use of the dating platform.</li>
                                                            <li>2.16 The word member shall include both the masculine and the feminine gender as well as juristic persons.</li>
                                                            <li>2.17 Words importing the singular meaning where the context so admits include the plural meaning and vice versa.</li>
                                                            <li>2.18 Headings in these Terms and Conditions are for convenience purposes only and do not affect the interpretation of this Agreement.</li>
                                                        </ul>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                            3. ACCEPTANCE OF THE TERMS AND CONDITIONS
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                        <ul>
                                                            <li>3.1 Prior to using our dating platform, it is essential that you thoroughly read and comprehend these Terms and Conditions.</li>
                                                            <li>3.2 These terms will govern the usage and functionality of our platform, as well as the services available on it.</li>
                                                            <li>3.3 Upon understanding, you will be prompted to register on our platform.</li>
                                                            <li>3.4 By registering, you acknowledge that you have read and agreed to our Terms and Conditions, as presented on our official website.</li>
                                                            <li>3.5 Additionally, by following all prompts and completing the registration process, it is assumed that you have read, understood, and accepted these Terms and Conditions.</li>
                                                            <li>3.6 Your utilization of our platform signifies your agreement to adhere to and be bound by these Terms and Conditions.</li>
                                                            <li>3.7 You declare that these Terms and Conditions do not prejudice any other agreements you may have, whether legally or otherwise, regarding the services offered.</li>
                                                            <li>3.8 The Member explicitly acknowledges a full understanding of the provisions in this Agreement and voluntary participation for their own benefit.</li>
                                                            <li>3.9 Acceptance of these Terms and Conditions grants us authorization to verify your identity and perform necessary checks to ensure a safe and secure environment for all members.</li>
                                                            <li>3.10 We reserve the right to amend or modify these Terms and Conditions periodically. The continued use of our platform constitutes your agreement to be bound by the terms of any such amendment or variation.</li>
                                                        </ul>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                            4. REGISTRATION
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                        <ul>
                                                            <li>4.1 To access our various services, it is necessary to register by creating an account on our platform. You agree to safeguard the confidentiality of your chosen passwords or account identifiers and take responsibility for all activities under your account.</li>
                                                            <li>4.2 Upon signing up or using our platform, you confirm having read and comprehended our registration Terms and Conditions on our official website. Your agreement to these Terms and Conditions is implicit in your use of the platform.</li>
                                                            <li>4.3 You explicitly consent to our obtaining and verifying your personal information as necessary to ensure the security and integrity of our platform. This includes authorization to use third-party services for identity verification purposes.</li>
                                                            <li>4.4 We may request additional information related to your profile or usage of our services, and failure to provide such information within the specified time may result in rejection of your registration or suspension of your account.</li>
                                                            <li>4.5 If you do not accept these Terms and Conditions, do not proceed with the registration process. Your use of the platform and completion of the registration signifies your acceptance of these Terms and Conditions.</li>
                                                            <li>4.6 For any specific service or feature you use, you should thoroughly understand the specific terms and conditions pertaining to that service. Once accepted, these will bind you.</li>
                                                        </ul>

                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                            6. EXCLUSION OF LIABILITY
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <ul>
                                                                <li>7.1 MatchKenya is not responsible for losses resulting from system disruptions beyond its control, including:</li>
                                                                <li>7.1.1 Equipment failures or force majeure events;</li>
                                                                <li>7.1.2 Telecommunications failures or adverse conditions.</li>
                                                                <li>7.2 MatchKenya is not liable for:</li>
                                                                <li>7.2.1 Equipment failures;</li>
                                                                <li>7.2.2 Fraudulent or illegal use of the platform;</li>
                                                                <li>7.2.3 Your non-compliance with these Terms.</li>
                                                                <li>7.3 Implied warranties and obligations are excluded to the maximum extent permitted by law.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                                            7. INTELLECTUAL PROPERTY RIGHTS
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <ul>
                                                                <li>8.1 MatchKenya owns the intellectual property rights of the system and related documentation.</li>
                                                                <li>8.2 Do not reproduce or manipulate the system or documentation without Quicksava's consent.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed text-dark"  type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                                            8. YOUR REQUESTS
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <ul>
                                                                <li>9.1 Your requests are assumed to be made by you and you authorize MatchKenya to act on them.</li>
                                                                <li>9.2 MatchKenya may act on incomplete or ambiguous requests if it believes it can resolve them.</li>
                                                                <li>9.3 MatchKenya may require further confirmation from you before acting on a request.</li>
                                                                <li>9.4 Indemnify Quicksava from any claims or losses arising from actions taken on your requests.</li>
                                                                <li>9.5 MatchKenya is not liable for transactions on your equipment, regardless of negligence.</li>
                                                                <li>9.6 MatchKenya will comply with legal orders regarding your loan account.</li>
                                                                <li>9.7 In case of conflict, these Terms take precedence over your requests.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TermsAndConditions;
