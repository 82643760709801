import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { invoices } from '../store/invoice.store';
import PageHeader from '../component/layout/pageheader';
import PayAccordion from './payAccordion';
import { paymentInstructions } from '../store/payments.store';
import Loader from '../utils/Loader';


const Invoices = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const invoice_data = useSelector((state) => state?.invoices?.invoices?.results);
    const [activeRow, setActiveRow] = useState(null);
    const [pay, setPay] = useState(false)
    const [payData, setPayData] = useState({});
    const current_component = useSelector((state) => state?.application?.current_component)

    function handlePayClicked(amount, reference_number) {
        setActiveRow(reference_number)
        setPay(!pay)
        const pay_data = {
            "amount": amount,
            "bill_reference_number": reference_number,
            "payment_notes": ""
        };

        setPayData(pay_data)

        //dispatch call to fetch instrcutions here, only call it when the pay state is true
        if(!pay){
            dispatch(paymentInstructions(pay_data));
        }
    }

    useEffect(() => {
        if(current_component === "invoices"){
            dispatch(invoices());
        }
    }, [current_component]);

    return (
        <div>
            {/* <PageHeader title={'Invoices'}  show={true}/> */}
            <section className="member-page-section">
                <div className="container">
                    <div className="member-wrapper">
                        <ul className="member-info mb-4">
                            <li className="all-member">
                                <p>All Invoices </p>
                            </li>
                        </ul>
                        <div className="row   px-2">
                            {invoice_data?.length > 0 ? (
                                invoice_data.map((invoice) => (
                                    <div className="shadow" key={invoice.id}>
                            
                                            <table className="table shadow">
                                                <thead>
                                                    <tr>
                                                        <th scope="col"><small>Reference</small></th>
                                                        <th scope="col"><small>Amount</small></th>                                                      
                                                        <th scope="col"><small>Status</small></th>
                                                        <th scope="col"><small>Action</small></th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{invoice.reference_number}</td>
                                                        <td>{invoice.amount}</td>                                                
                                                        <td>{invoice.status}</td>
                                                        {invoice.status == "PENDING" ? (
                                                                <>
                                                                    <td><button className={`${pay && activeRow == invoice.reference_number ? "btn-danger" : "btn-success"}  btn  btn-sm py-0 px-3 mx-2 rounded-pill`}
                                                                        onClick={() => handlePayClicked(invoice.amount, invoice.reference_number)}
                                                                    ><small>{pay && activeRow == invoice.reference_number ? " X " : "Pay"}</small></button>
                                                                    
                                                            <button className='btn-sm py-0 btn-success btn px-3 mx-2 rounded-pill' 
                                                            onClick={()=>navigate(`/invoice/${invoice.id}`)}
                                                            ><small>View</small></button>

                                                                    </td>
                                                                </>
                                                            ) : (
                                                                <td></td>
                                                            )}
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4" className={` ${activeRow == invoice.reference_number && pay ? '' : 'd-none'}`}>
                                                            <PayAccordion active={activeRow == invoice.reference_number} paydata={payData} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                  
                                    </div>
                                ))): (<Loader/>)}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Invoices;
