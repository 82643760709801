import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MemberItem from '../items/memberitem';
import Events from '../../pages/events';


const title = "New Events in Nairobi";
const subtitle = "See the latest events";


class MemberSection extends Component {
    render() { 
        return (
            <section className="member-section padding-tb">
                <div className="container">
                    <div className="section-header">
                        <h4 className="theme-color">{subtitle}</h4>
                        <h2> {title}</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row justify-content-center g-3 g-md-4">
                            {/* <MemberItem /> */}
                            <Events show={false}/>
                        </div>
                    </div>

                </div>
            </section>
        );
    }
}
 
export default MemberSection;