/* eslint-disable class-methods-use-this */
import axios from 'axios';
import _ from 'lodash';
import {getFromLocalStorage} from "../utils/local-storage";


class Client {
    //The constructor, attributes definitions
    constructor(){
        //Setting the header variables
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.common['Accept'] = '*/*';
        axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';


        const meta = document.head.querySelector('meta[name="csrf-token"]');
        if (meta) {
            axios.defaults.headers.common['X-CSRF-TOKEN'] = meta.content;
        }

        axios.defaults.headers.common.Authorization = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjA2YTViZjQ2LWIwZDUtNDgzMC05MTA2LTE5MDg1MTIwNGY0ZiIsImZ1bGxfbmFtZSI6IlRyZXZvciAgT2dpbmEiLCJmaXJzdF9uYW1lIjoiVHJldm9yIiwib3RoZXJfbmFtZXMiOm51bGwsInN1cm5hbWUiOiJPZ2luYSIsImJpbyI6IlRyYXZlbCBhZGRpY3QgZHJlYW1pbmcgb2YgbXkgbmV4dCBkZXN0aW5hdGlvbi4iLCJhZ2UiOjI0LCJwaG9uZV9udW1iZXIiOiIyNTQ3OTU3ODE3OTQiLCJlbWFpbCI6Im90aXRyZXZvcjQ1MEBnbWFpbC5jb20iLCJwZXJtaXNzaW9ucyI6W10sImlhdCI6MTcyMDU1NjIzOCwiZXhwIjoxNzIzMTQ4MjM4fQ.0TaoKSDOuS4CN5ZHvGcUE_OQDE42MOAjj7H8Pcaky7w`

    
        //creating the base url
        this.http = axios.create({
            baseURL : "https://portal.matchkenya.com/api/v1"    //local
        });


        this.http.interceptors.response.use(response => response, async (error) => {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                if (!window.location.href.includes("/login") && !window.location.href.includes("/register")) {
                    window.location.href = '/login';
                }

                window.location.href = '/login';

            }
            return Promise.reject(error);
        });
    }

      
    //The functions
    get(path, params, config) {
        return this.http.get(path, {params, ...config});
    }

    post(path, data, config) {
        return this.http.post(path, data, config);
    }

    upload(path, data, file, config) {
        const form = new FormData();
        _.each(data, (value, key) => {
            form.append(key, value);
        });
        return this.post(path, form, {
            ...config,
            headers: {'Content-Type': 'multipart/form-data'},
        });
    }

    put(path, data, config) {
        return this.http.put(path, data, config);
    }

    delete(path, params, config) {
        return this.http.delete(path, {params, ...config});
    }

    parseResponse({data}) {
        return data;
    }
}

export default new Client();
