import { useNavigate, Link } from 'react-router-dom';
import React,{useState} from 'react';
import { HouseDoorFill, CalendarEventFill, GiftFill, Person } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { update_component } from '../../store/application.store';



const BottomBar = React.memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [crumb,setCrumb]=useState("members")

  const handleClick =(value)=>{
    setCrumb(value)
    console.log("This is the value", value)
    dispatch(update_component(value))

  }


  return (
    <div className="bottom-bar row pb-5">
      <Link className='col-3' onClick={()=>handleClick("members")}>
        <HouseDoorFill className="icon"/>
      </Link>

      {/* <Link to="/my-booked-events" className='col-3'>
        <CalendarEventFill className="icon"/>

      </Link>

      <Link to="/my-packages" className='col-3'>
        <GiftFill className="icon" />
      </Link> */}

      <Link className='col-3' onClick={()=>handleClick("account")}>
        <Person className="icon" />
      </Link>
    </div>
  )
})

export default BottomBar;
