import React, { useEffect } from 'react';
import { BiMap, BiMoney, BiTime } from 'react-icons/bi';
import PageHeader from '../component/layout/pageheader';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from 'nuka-carousel';
import { even_t } from '../store/events.store';

const Event = () => {
    const id = useParams();
    const dispatch = useDispatch();
    const event = useSelector((state) => state?.events?.event);

    useEffect(() => {
        dispatch(even_t(id));
    }, []);

    return (
        <div className="profile-page">
            <PageHeader title={'Event Details'} show={true}/>
            <section className="profile-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <h5 className="card-title">{event?.name}</h5>
                                    <hr/>
                                    <p className="card-text"><BiMap size={100} /> <span className="label">Location:</span> {event?.event_location}</p>
                                    <p className="card-text"><BiMoney size={50} /> <span className="label">Charges:</span> Ksh {event?.charges}</p>
                        
                                    <p className="card-text text-muted"><i>{event?.details}</i></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Event Images</h5>
                                    <hr/>
                                    {event?.images.length > 0 ? (
                                        <Carousel>
                                            {event.images.map((image, index) => (
                                                <img key={index} src={image} alt={`Image ${index}`} />
                                            ))}
                                        </Carousel>
                                    ) : (
                                        <p>No images available</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Event;
