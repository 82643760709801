import client from "./client";

export default {
    async likeUser(id){
        return client.parseResponse(await client.post('/users/like',id))
    },

    async userLikes(){
        return client.parseResponse(await client.get('/dating/user-likes'))
    },

    async addUserPrefference(data){
        return client.parseResponse(await client.post('/dating/update-user-preference',data))
    },

    async userMatches(){
        return client.parseResponse(await client.get('/dating/user-matches'))
    },
}   