import { Spinner } from 'react-bootstrap';

const Loader = () => {
    return (
        <div className='loader' style={{
            position:"absolute",
            left:"40vw",
            top:"40vh"
        }}>


        <Spinner
            animation="grow"
            role="status"
            style={{
                backgroundImage: 'url(https://matchkenya.com/assets/images/logo/logo.png)', 
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '3rem',
                height: '3rem',
                color: '#7d6e97', // Set the color to #391965
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)"
            }}
        >
            <span className="visually-hidden">Loading...</span>
        </Spinner>


        </div>
    )
}

export default Loader;